@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
*{
  color: #000000;
}
body {
  font-family: "Poppins", sans-serif !important;
  background-color: #fff;
}

.nav-logo {
  height: 34px;
}

.navbar>.container {
  align-items: center;
}

.navbar {
  padding: 10px;
  background-color: #fff !important;
}

.navbar .nav-link {
  font-size: 14px;
  font-weight: 400;
  display: flex;
  align-items: center;
  color: rgba(132, 132, 132, 1);
  justify-content: center;
}

.dropdown-toggle::after {
  margin-left: 0.75rem;
}

.nav-btn {
  background-color: #0255F1 !important;
  width: 170px;
  padding: 12px !important;
  color: #fff !important;
  border-radius: 3px !important;
  line-height: 100% !important;
}

.hero-content .nav-btn {
  width: 250px;
  border-radius: 100px !important;
  border: 2px solid rgba(14, 239, 255, 1);
}

.nav-btn:hover {
  border-color: #0255F1 !important;
}

.hero-content {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.navbar .nav-btn {
  background-image: linear-gradient(110deg, rgba(14, 239, 255, 1), rgba(2, 85, 241, 1)) !important;
  border-color: rgba(14, 239, 255, 1);
}

.navbar::after,
.navbar::before {
  content: "";
  position: absolute;
  height: 29px;
  width: 23px;
  /*background-color: #FFB800;*/
  top: 16px;
}

.navbar::before {
  left: 0;
}

.navbar::after {
  right: 0;
}

.hero-title {
  display: inline-block;
  font-size: 86px;
  font-weight: 700;
  /* Fallback solid color */
  background-color: #0255F1;

  /* Linear gradient background */
  background-image: linear-gradient(70deg, rgba(1, 49, 139, 1) 0%, rgba(2, 85, 241, 1) 65%, rgba(14, 239, 255, 1) 100%);

  /* Clip the gradient to the text */
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.hero-para {
  font-size: 24px;
  color: #000000;
}

body .text-primary {
  color: #0255F1 !important;
}

.hero-para {
  max-width: 420px;
}

.page-info{
  max-width: 930px;
  align-items: center;
}
.page-title{
  margin-bottom: 0 !important;
  font-size: 40px;
}

.app-title{
  color: #0255F1;
}

.page-desc{
  font-size: 20px;
}

.app-card{
  display: flex;
  flex-direction: column;
  gap: 17px;

}

.ac-img{
  border-radius: 32px;
  width: 100%;
  aspect-ratio: 4/2;
  object-fit: cover;
  object-position: center;
}
.ac-pill{
  padding: 14px;
  margin-bottom: 0;
  border-radius: 45px;
  text-align: center;
  line-height: 100%;
}

.ac-pill-1{
  background-image: linear-gradient(to right, #0EEFFF, #0255F1);
}

.ac-pill-2{
  background-image: linear-gradient(to right, #0255F1, #0255F1);
}

.ac-pill-3{
  background-image: linear-gradient(to right, #0255F1, #0EEFFF);
}
.ab-img img{
  width: 420px;
}
.abc-desc{
  max-width: 340px;
}
.app-box{
  display: flex;
  gap: 50px;
  padding: 60px 100px 0;
  background-color: rgba(242, 246, 254, 1);
  border-radius: 32px;
}
.app-box-outer{
  padding: 2px;
  border-radius: 32px;
  background-image: linear-gradient(#CACACA, #0255F1);
}

.ab-content{
  display: flex;
  flex-direction: column;
  gap: 24px;
  justify-content: space-between;
}

.app-qr-img{
  height: 114px;
  width: 114px;
}

.app-qr-desc{
  color: #848484;
}

.stores-flex{
  gap: 40px;
}
.ab-img{
  align-self: flex-end;
  text-align: center;
}
.store{
  text-align: center;
  font-size: 14px;
  font-weight: 500;
}
.light-text{
  color: #848484 !important;
}
.store p{
  color: #848484;
}
.sf-desc{
  color: #848484;
  font-size: 13px;
  font-weight: 500;
  max-width: 198px;
}
.step-flex{
  display: flex;
  align-items: center;
  gap: 20px;
}
.sf-img{
  height: 93px;
  width: 93px;
}

.steps-title{
  background-color: #0255F1;
  background-image: linear-gradient(90deg, #01318B 0%, #0255F1 56%, #0EEFFF 99%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.steps-pill{
  height: 90px;
}

.st-2{
  width: 450px;
}

.trade-flex{
  display: flex;
  column-gap: 25px;
  row-gap: 48px;
  flex-wrap: wrap;
  align-items: center;
}
.tf-card{
  background-color: #DEE9EC;
  padding: 48px 30px 16px;
  width: 230px;
  border-radius: 13px;
  font-weight: 500;
  position: relative;
}

.tf-pill{
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80%;
  margin: 0;
  background-color: #0255F1;
  border-radius: 13px;
  padding: 16px 20px;
}

.step-bottom{
  background-image: url(./assets/steps-bg.png);
  background-position: right bottom;
  background-size: 40% auto;
  background-repeat: no-repeat;
}
.steps-bg{
  width: 415px;
  max-width: 100%;
}

.media-box-outer{
  padding: 2px;
  border-radius: 13px;
  background-image: linear-gradient(210deg, #0255F1, #0EEFFF);
  height: 325px;
}
.media-bottom-box-outer{
  padding: 2px;
  border-radius: 32px;
  background-image: linear-gradient(180deg, #5E8BDF, #0255F1);
  position: relative;
}
.media-box{
  height: 100%;
  border-radius: 13px;
  background-color: rgba(242, 246, 254, 1);
}

.mb-gift{
  position: absolute;
  top: 50%;
  right: 30px;
  transform: translateY(-50%);
  z-index: 0;
}
.media-bottom-box{
  height: 100%;
  border-radius: 32px;
}
.media-bottom-box>div{
  position: relative;
  z-index: 1;
}

.mbb-1 .media-bottom-box{
  background-color: #0255F1;
}

.mbb-2 .media-bottom-box{
  background-color: #0EEFFF;
}
.mb-title{
  font-size: 32px;
}
.mbb-btn{
  width: 250px !important;
  max-width: 100%;
  text-align: center;
  padding: 11px 16px !important;
  font-size: 14px !important;
  font-weight: 700 !important;
  width: fit-content;
  line-height: 100% !important;
  border-radius: 100px !important;
}

.media-bottom-box .page-desc{
  max-width: 370px;
}

.mbb-1 .page-desc{
  color: #0EEFFF;
}

.mbb-2 .page-desc{
  color: #0255F1;
}

.mbb-1 .mbb-btn{
  color: #0255F1 !important;
  background-color: #0EEFFF !important;
}
body .btn-primary{
  border-color: #0255F1 !important;
  background-color: #0255F1 !important;
}
.mbb-2 .mbb-btn{
  color: #FFFFFF !important;
  background-color: #01318B !important;
}

.compare-title{
  font-size: 64px;
}

.faq-girl{
  max-height: 550px;
  width: auto !important;
  object-fit: contain;
}

.faq-sec{
  background-image: linear-gradient(135deg, #0EEFFF 0%, #0255F1 30%, #0255F1 70%, #0EEFFF 100%);
}

.faq-desc{
  color: #0EEFFF;
}
.accordion-item{
  box-shadow: rgba(0, 0, 0, 0.1) !important;
  border-radius: 6px !important;
  margin-bottom: 18px;
  overflow: hidden;
}
.accordion-button::after{
  font-size: 20px;
  font-weight: 500;
  content: "+" !important;
  background-image: unset !important;
}

.accordion-button:not(.collapsed)::after{
  color: #fff !important;
}
.accordion-button{
  box-shadow: none !important;
  display: flex !important;
  align-items: center !important;
  border-bottom: 0 !important;
  padding: 24px 20px !important;
  background-color: #fff !important;
  color: #0255F1 !important;
  font-size: 14px !important;
  font-weight: 700 !important;
}

.btn-faq{
  padding: 14px 40px !important;
  border-radius: 6px !important;
  background-image: linear-gradient(90deg, #0255F1, #0EEFFF) !important;
  font-size: 14px !important;
  color: #fff !important;
  font-weight: 700 !important;
  border: 2px solid #0EEFFF !important;
}

.accordion-body{
  font-weight: 11px !important;
  color: #000 !important;
  font-weight: 300 !important;
  padding: 0 20px 24px !important;
}

.lang-icon{
  position: absolute;
  left: 8px;
  top: 50%;
  transform: translateY(-50%);
}
.form-select{
  padding-left: 30px !important;
  background-image: url(./assets/polygon.svg) !important;
  background-size: 10px !important;
  border-radius: 3px !important;
  border-color:  #848484 !important;
}

.f-title{
  font-size: 24px;
  font-weight: 600;
}
.f-flex{
  gap: 6px;
}
.f-link{
  font-size: 20px;
  text-decoration: none;
  color: #000;
  font-weight: 300;
}
.f-right{
  max-width: 210px;
}
.f-socials{
  display: flex;
  flex-wrap: wrap;
  gap: 11px;
}
.f-social-link{
  height: 49px;
  width: 49px;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #0255F1;
  justify-content: center;
}
.f-downloads{
  display: flex;
  flex-direction: column;
  gap: 9px;
}

.fd-img{
  height: 60px;
}

.footer-small{
  font-size: 12px;
}

.f-social-link img{
  width: 32px;
  height: 32px;
  object-fit: contain;
}
@media (max-width: 1200px){
  .app-box{
    padding: 50px 50px 0 50px;
  }

  .mb-gift{
    top: unset;
    bottom: 0;
    transform: unset;
    right: 0px;
    height: 144px;
  }
}
@media (max-width: 992px) {
  .step-bottom{
    background-image: unset;
  }
  .steps-pill{
    height: 60px;
  }
  .trade-flex{
    justify-content: center;
  }
  .app-box{
    flex-direction: column-reverse;
  }
  .hero-title {
    font-size: 48px;
  }

  .hero-para {
    max-width: 100%;
    font-size: 20px;
  }

  .navbar::after,
  .navbar::before {
    width: 12px;
  }
  .page-title{
    font-size: 28px;
  }
  .ab-img{
    align-self: center;
  }
  .ab-img img{
    max-width: 100%;
  }

  .page-desc{
    font-size: 18px;
  }
  .ab-content.pb-5{
    padding-bottom: 0 !important;
  }
}

@media (max-width: 768px) {
  .hero-content{
    gap: 16px;
  }
  .ab-content .abc-info{
    text-align: center;
  }

}